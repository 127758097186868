import { Vue, Component } from 'vue-property-decorator';

import router from '@/router';

import CoursesService from '@/services/Courses/CoursesService';
import BookService from '@/services/Book/BookService';
import ExerciseService from '@/services/Exercise/ExerciseService';
import VideoService from '@/services/Video/VideoService';

import copyObject from '@/share/Util/copyObject';

import { CurrentCourse, TopicsCourse } from '@/globalInterfaces/Course';

let contextID: number | null = null;

const TYPE_VIDEOS = 'videos';
const TYPE_ARCHIVES = 'archives';
const TYPE_EXERCICES = 'exercices';

@Component
export default class CourseData extends Vue {
  protected currentParentTopicTitle = '';
  private topicCourse: Record<string, any> = {};
  protected currentCourseWeek: CurrentCourse | Record<string, any> = {};

  private idsCourse: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: [],
  };

  private filesCourse: Record<string, any> = {
    videos: [],
    archives: [],
    exercices: [],
  };

  private courseID = -1;

  protected CoursesService = new CoursesService();
  protected VideoService = new VideoService();
  private bookService = new BookService();
  private ExerciseService = new ExerciseService();

  get currentCourse() {
    const { path } = router.currentRoute.params;
    const splitPath = path.split('&');
    const idCourses = splitPath[1];
    const listCourses = this.$store.getters.courses;

    const currentCourse = listCourses.find((course: Record<string, any>) => course.idCourses === Number(idCourses));

    return currentCourse;
  }

  async setTopicCourse() {
    try {
      const { path, topicID } = router.currentRoute.params;
      const splitPath = path.split('&');

      if (splitPath[1] && splitPath[2] && splitPath[3]) {
        const idCourses = splitPath[1];
        const idClass = splitPath[2];
        const weekID = splitPath[3];

        const response = await this.CoursesService.getClassWeekCourse(idCourses, idClass);

        if (response && response.length) {
          const currentCourseWeek = this.filterWeekCourse(response, Number(weekID));

          await this.loadFilesCourse(currentCourseWeek);

          this.currentCourseWeek = this.addFilesCourse(currentCourseWeek);

          const topic = this.searchTopicCourse(this.currentCourseWeek, Number(topicID));

          return {
            topic,
            contextID,
          };
        }
      }

      throw new Error('');
    } catch (error) {
      console.error(error);
      throw new Error('');
    }
  }

  filterWeekCourse(course: Array<Record<string, any>>, weekId: number) {
    const foundWeekCourse = course.find((week: Record<string, any>) => week.id === weekId);
    if (foundWeekCourse) return foundWeekCourse;
    return {};
  }

  async loadFilesCourse(week: Record<string, any>) {
    try {
      const { path } = router.currentRoute.params;
      const splitPath = path.split('&');
      const weekID = splitPath[3];

      if (!(Number(weekID) === this.courseID)) {
        this.getIdsCourse(week, TYPE_VIDEOS);
        this.getIdsCourse(week, TYPE_ARCHIVES);
        this.getIdsCourse(week, TYPE_EXERCICES);

        await this.getFilesVideos(this.idsCourse.videos, TYPE_VIDEOS);
        await this.getFilesMaterials(this.idsCourse.archives, TYPE_ARCHIVES);
        await this.getFilesExercices(this.idsCourse.exercices, TYPE_EXERCICES);
      }

      this.courseID = Number(weekID);
    } catch (error) {
      console.error(error);
    }
  }

  getIdsCourse(week: Record<string, any>, type: string) {
    if (week?.topics && week?.topics.length) {
      week.topics.forEach((topic: Record<string, any>) => {
        this.getIdsTopicCourse(topic, type);
      });
    }
  }

  getIdsTopicCourse(topic: Record<string, any>, type: string) {
    if (!(topic?.topics && topic?.topics.length)) {
      let ids = [];

      if (topic?.[type] && topic?.[type].length) {
        ids = topic[type].map((file: Record<string, any>) => file.id);

        this.idsCourse[type] = [...this.idsCourse[type], ...ids];
      }
    }

    if (topic?.topics && topic?.topics.length) {
      topic.topics.forEach((topicItem: Record<string, any>) => {
        this.getIdsTopicCourse(topicItem, type);
      });
    }
  }

  async getFilesVideos(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.VideoService.getVideos(ids.slice(0, 100));
      this.filesCourse[filesParam].push(...response);
      await this.getFilesVideos(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.VideoService.getVideos(ids);
      this.filesCourse[filesParam].push(...response);
    }
  }

  async getFilesMaterials(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.bookService.getArchives(ids.slice(0, 100));
      this.filesCourse[filesParam].push(...response);
      await this.getFilesMaterials(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.bookService.getArchives(ids);
      this.filesCourse[filesParam].push(...response);
    }
  }

  async getFilesExercices(ids: Array<number>, filesParam: string) {
    if (ids && ids.length > 49) {
      const response = await this.ExerciseService.getExercises(ids.slice(0, 100));
      this.filesCourse[filesParam].push(...response);
      await this.getFilesExercices(ids.slice(100), filesParam);
    } else if (ids && ids.length) {
      const response = await this.ExerciseService.getExercises(ids);
      this.filesCourse[filesParam].push(...response);
    }
  }

  addFilesCourse(week: Record<string, any>) {
    const newWeek: Record<string, any> = copyObject(week);

    if (newWeek?.topics && newWeek?.topics.length) {
      newWeek.topics.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
        newWeek.topics[indexDiscipline] = this.setFilesCourse(newWeek.topics[indexDiscipline]);
      });
    }

    return newWeek;
  }

  setFilesCourse(topic: Record<string, any>) {
    const newTopic = topic;

    if (newTopic?.videos && newTopic?.videos.length) {
      const { videos } = this.filesCourse;

      const listFileType = newTopic?.videos.map((video: Record<string, any>) => video.id);
      newTopic.videos = videos.filter((videoItem: Record<string, any>) => listFileType.includes(videoItem.idVideo));
    }

    if (newTopic?.archives && newTopic?.archives.length) {
      const { archives } = this.filesCourse;

      const listFileType = newTopic?.archives.map((archive: Record<string, any>) => archive.id);
      newTopic.archives = archives.filter((archiveItem: Record<string, any>) => listFileType.includes(archiveItem.idArchive));
    }

    if (newTopic?.exercices && newTopic?.exercices.length) {
      const { exercices } = this.filesCourse;

      const listFileType = newTopic?.exercices.map((exercise: Record<string, any>) => exercise.id);
      newTopic.exercices = exercices.filter((archiveItem: Record<string, any>) => listFileType.includes(archiveItem.idExercice));
    }

    if (newTopic.topics && newTopic.topics.length > 0) {
      newTopic.topics.forEach((topicItem: Record<string, any>) => {
        this.setFilesCourse(topicItem);
      });
    }

    return newTopic;
  }

  searchTopicCourse(week: CurrentCourse | Record<string, any>, idTopic: number) {
    if (week?.topics && week?.topics.length) {
      week.topics.every((topic: TopicsCourse, index: number) => {
        const foundDiscipline = this.getTopicCourse(topic, idTopic);
        if (foundDiscipline) {
          this.currentParentTopicTitle = week?.topics[index]?.title || '';
          contextID = topic.disciplineID;
          return false;
        }

        return true;
      });
    }

    return this.topicCourse;
  }

  getTopicCourse(topic: TopicsCourse, idTopic: number): boolean {
    let foundTopic = false;

    if (topic?.idTopic && topic?.idTopic === idTopic) {
      this.topicCourse = topic;
      foundTopic = true;
    }

    if (topic?.topics && topic?.topics.length) {
      topic.topics.every((topicItem: TopicsCourse) => {
        foundTopic = this.getTopicCourse(topicItem, idTopic);
        return !foundTopic;
      });
    }
    return foundTopic;
  }
}
