import store from '@/store';

import AppClient from '../AppClient';

import { dataProcessingPlanner, addCheckedTrueTopics } from './processingPlanner';

export default class PlannerService {
  private basePath = process.env.VUE_APP_PLANNER_API;
  private headersConfig = { headers: { Authorization: store.getters.token } };

  private Client = new AppClient({ url: this.basePath });

  async postUserConfig(userConfig: Record<string, any>) {
    const URI = '/user';

    const response = await this.Client.post(URI, userConfig, this.headersConfig);

    store.commit('setPlannerConfigUser', response?.data);
    return response?.data;
  }

  async postValidate(plannerConfig: Record<string, any>) {
    const URI = '/materials';

    const response = await this.Client.post(URI, plannerConfig, this.headersConfig);

    store.commit('setPlannerValidateData', response?.data);
    return response?.data;
  }

  async postTopics(topicsConfig: Record<string, any>) {
    try {
      const URI = '/disciplines';

      const response = await this.Client.post(URI, topicsConfig, this.headersConfig);

      const responseAddChecked = addCheckedTrueTopics(response?.data);

      store.commit('setPlannerTopics', responseAddChecked);
      return responseAddChecked;

    } catch (err) {
      return err;
    }
  }

  async savePlanner(plannerConfig: Record<string, any>) {
    try {
      const URI = '/create';

      const response = await this.Client.post(URI, plannerConfig, this.headersConfig);

      return response;
    } catch (err) {
      return err;
    }
  }

  async getPlanner() {
    try {
      const plannerWeek = store.getters.plannerResponse.week;

      if (plannerWeek?.weeks && plannerWeek?.weeks.length) {
        return plannerWeek;
      }

      const URI = '/getweek';

      const response = await this.Client.get(URI, {}, this.headersConfig);

      const weeks = dataProcessingPlanner(response?.data);

      store.commit('setPlannerWeek', weeks);

      return weeks;
    } catch (err) {
      return err;
    }
  }
}
