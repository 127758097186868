function sortTitle(listFiles: Array<Record<string, any>>) {
  const sortFiles = listFiles.sort((a: Record<string, any>, b: Record<string, any>) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });

  return sortFiles;
}

export function orderedTopicsDiscipline(discipline: Record<string, any>) {
  const newDiscipline = discipline;

  if (!(newDiscipline?.topics && newDiscipline?.topics.length)) {
    return newDiscipline;
  }

  newDiscipline.topics = sortTitle(newDiscipline.topics);
  newDiscipline.topics.forEach((topicItem: Record<string, any>) => {
    orderedTopicsDiscipline(topicItem);
  });

  return newDiscipline;
}

export function dataProcessingDisciplinePlanner(grid: Array<Record<string, any>>) {
  let newGrid = grid;

  if (!(newGrid && newGrid.length)) {
    return newGrid;
  }

  newGrid = sortTitle(newGrid);

  newGrid.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
    newGrid[indexDiscipline] = orderedTopicsDiscipline(newGrid[indexDiscipline]);
  });

  return newGrid;
}

export function dataProcessingGridPlanner(week: Record<string, any>) {
  const newWeekTopic = week;

  if (!(newWeekTopic?.grid && newWeekTopic?.grid.length)) {
    return newWeekTopic;
  }

  newWeekTopic.grid.forEach((grid: Record<string, any>, indexGrid: number) => {
    newWeekTopic.grid[indexGrid] = dataProcessingDisciplinePlanner(newWeekTopic.grid[indexGrid]);
  });

  return newWeekTopic;
}

export function dataProcessingPlanner(planner: Record<string, any>) {
  const newWeeksPlanner = planner;

  if (!(newWeeksPlanner?.weeks && newWeeksPlanner?.weeks.length)) {
    return newWeeksPlanner;
  }

  newWeeksPlanner.weeks.forEach((week: Record<string, any>, indexWeek: number) => {
    newWeeksPlanner.weeks[indexWeek] = dataProcessingGridPlanner(newWeeksPlanner.weeks[indexWeek]);
  });

  return newWeeksPlanner;
}

export function addCheckedTrueTopics(topics: Array<Record<string, any>>) {
  if (!topics || !topics.length) return topics;

  const newTopicsContent: Array<Record<string, any>> = topics;

  newTopicsContent.forEach((topic: Record<string, any>, indexTopic: number) => {
    newTopicsContent[indexTopic].checked = true;

    if (topic?.topics && topic?.topics.length) {
      addCheckedTrueTopics(topic?.topics);
    }
  });

  return newTopicsContent;
}
