








import { Component, Prop, Vue } from 'vue-property-decorator';
import ExerciseIcon from '@/assets/icons/Exercise.vue';
import MaterialIcon from '@/assets/icons/Material.vue';
import VideoIcon from '@/assets/icons/Video.vue';

@Component({
  components: {
    ExerciseIcon,
    MaterialIcon,
    VideoIcon,
  },
})
export default class ChooseIcon extends Vue {
  @Prop() icon!: string;
}
