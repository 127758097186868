





































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import Accordion from '@/components/Accordion/index.vue';

import route from '@/router';

import SubjectOptionsSidebar from './SubjectOptionsSidebar/index.vue';

@Component({
  name: 'SidebarTree',
  components: {
    Accordion,
    SubjectOptionsSidebar,
  },
})
export default class SidebarAccordion extends Vue {
  @Prop() data!: Array<Record<string, any>>;
  @Prop({ default: 0 }) indexAccordion!: number;
  @Prop() type!: string;
  @Prop({ default: () => [] }) arrayIndexesOpen!: any;
  @Prop() context!: string;

  private arrayIndexes: Array<string> = []

  created() {
    this.arrayIndexes = this.arrayIndexesOpen;
    this.openIndex(this.data);
    this.arrayIndexes = this.arrayIndexes.reverse();
  }

  get getPage() {
    return route.currentRoute.name;
  }

  setHash() {
    return Math.floor(Math.random() * (40 - 0)) + 0;
  }

  verifyId() {
    const isVideo = this.type === 'videos';
    return {
      page: isVideo ? 'videos' : 'archives',
      idObject: isVideo ? 'idVideo' : 'idArchive',
    };
  }

  setShow(id: string) {
    if (this.arrayIndexes && this.arrayIndexes.length) {
      return this.arrayIndexes.includes(id);
    }

    return false;
  }

  openIndex(data: Array<Record<string, any>>) {
    const { fileID } = route.currentRoute.params;

    data.every((itemData: Record<string, any>) => {
      const foundIndex = this.TreeSearchOpenIndexes(itemData, fileID, this.verifyId().page, this.verifyId().idObject);
      if (foundIndex) {
        return false;
      }
      return true;
    });
  }

  TreeSearchOpenIndexes(tree: Record<string, any>, fileID: string, param: string, idType: string) {
    if (!(tree.topics && tree.topics.length > 0)) {
      if (tree[param] && tree[param].length) {
        return tree[param].some((file: Record<string, any>) => {
          if (String(file[idType]) === String(fileID)) {
            this.arrayIndexes.push(file[idType]);
            if (this.context !== 'planner') {
              this.arrayIndexes.push(tree?.idTopic);
            }
            return true;
          }
          return false;
        });
      }
      return false;
    }

    return tree.topics.some((topic: Record<string, any>) => {
      const result = this.TreeSearchOpenIndexes(topic, fileID, param, idType);
      if (result) {
        if (tree?.id) {
          this.arrayIndexes.push(tree.id);
        }
        if (tree?.idTopic) {
          this.arrayIndexes.push(tree.idTopic);
        }
        this.arrayIndexes.push(topic.idTopic);
        return true;
      }
      return false;
    });
  }

  showOptions(topic: Record<string, any>) {
    if ((topic.videos && topic.videos.length) || (topic.archives && topic.archives.length) || (topic.exercice && topic.exercice.length)) {
      return true;
    }
    return false;
  }

}
