













































































































































import {
  Component, Vue, Watch, Mixins, Provide,
} from 'vue-property-decorator';

import { concat } from 'lodash';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { LIST_NAME_CONTEXT } from '@/constant/Context';
// import { LIST_PLAN_ID } from '@/constant/PlansIDs';
// import { URL_BUTTON_CREDIT_BOOK } from '@/constant/FreePlanUrls';
// import { LIST_PERMISSION } from '@/constant/ListPermission';

import PlannerData from '@/mixins/PlannerData';
import CourseData from '@/mixins/CourseData';
import BookData from '@/mixins/BookData';
import listAccordion from '@/mixins/listAccordion';
// import Credits, { ContextOptionsCredits } from '@/mixins/Credits';
import SetSidebar from '@/mixins/SetSidebar';
// import Permission from '@/mixins/permission';

import { Book, ContentHistoric, ListBookTopics } from '@/globalInterfaces/Book';
import { Video, File } from '@/globalInterfaces/Video';
import {
  FileVideo,
  FileCheckedStatus,
  FileCourse,
  FilePlanner,
  FilterTeachersVideo,
} from './interface';
// import { VideosHistoric } from '@/globalInterfaces/Video';

import { TypeMaterial } from '@/services/Historic/historicInterface';
import HistoricService from '@/services/Historic/historicService';

import VideoSidebar from './components/VideoSidebar/VideoSidebar.vue';

const PageHeader = () => import('@/components/PageHeader/PageHeader.vue');
const BoxContainer = () => import('@/components/BoxContainer/BoxContainer.vue');
const ContainerFluid = () => import('@/components/ContainerFluid/ContainerFluid.vue');
const ListAllBooks = () => import('@/components/ListAllBooks/index.vue');
const Footer = () => import('@/components/Footer/Footer.vue');
const WrapperContent = () => import('@/components/WrapperContent/WrapperContent.vue');
const OptionsContent = () => import('@/components/OptionsContent/OptionsContent.vue');
const SubjectTools = () => import('@/components/SubjectTools/SubjectTools.vue');
const WrapperVideoList = () => import('./components/WrapperVideoList/index.vue');
const PlayerMessages = () => import('./components/PlayerMessages/index.vue');
// const CreditBox = () => import('@/components/CreditBox/index.vue');
// const FeedbackCredits = () => import('./components/FeedbackCredits/index.vue');
const PlayerVideoVimeo = () => import('@/widgets/Video/PlayerVideo/PlayerVideoVimeo.vue');
const PlayerVideoYouTube = () => import('@/widgets/Video/PlayerVideo/PlayerVideoYouTube.vue');
const PlayerVideoLoading = () => import('@/widgets/Video/PlayerVideo/PlayerVideoLoading.vue');

const COMPONENT_YOUTUBE = 'PlayerVideoYouTube';
const COMPONENT_VIMEO = 'PlayerVideoVimeo';
const VIDEO_YOUTUBE = 'youtube';
// const QUANTITY_UNLIMITED_CREDIT = 99999;

interface ListFiles {
  files: Array<FileVideo | Record<string, any>>;
  group: number;
}

@Component({
  components: {
    PageHeader,
    WrapperContent,
    PlayerVideoVimeo,
    PlayerVideoYouTube,
    BoxContainer,
    ContainerFluid,
    OptionsContent,
    SubjectTools,
    ListAllBooks,
    Footer,
    WrapperVideoList,
    PlayerMessages,
    PlayerVideoLoading,
    // CreditBox,
    // FeedbackCredits,
  },
})
// export default class Videos extends Mixins(SetSidebar, PlannerData, CourseData, BookData, listAccordion, SetTutorial, Credits, Permission) {
export default class Videos extends Mixins(SetSidebar, PlannerData, CourseData, BookData, listAccordion) {
  private isMobile: boolean = this.$responsive.isMobile;

  private activeMessageVideo = false;
  private disabledVideoMessage = false;
  private btnClickReplayVideo = false;
  private activeBtnProgress = false;
  private currentChecked = false;
  private lastVideoList = false;
  private loadingVideosList = true;
  private loadingVideo = true;
  private isLoadingBooks = true;
  private errorLoadingListTopic = false;
  // private viewedVideo = false;

  private progressButtonTimer: any | null = null;
  private widthBackgroundProgress = '0';
  private typeMessage = '';
  private componentKey = 0;
  private timeLoadingVideo = 0;
  private idSetIntervalMessageVideo = 0;
  private idTimeoutMessageVideo = 0;

  private file: File | null = null;
  private list: ContentHistoric | FileCourse | FilePlanner | Record<string, any> = {};
  private listVideos: ListFiles[] = [];
  private ids: Array<number> = [];
  private allChecked: Array<FileCheckedStatus> = [];

  private tabMenu = [
    { title: 'Comentários', slug: 'comments', active: true },
    { title: 'Dúvidas', slug: 'doubts', active: false },
    { title: 'Anotações', slug: 'notes', active: false },
    { title: 'Exercícios', slug: 'exercises', active: false },
    { title: 'Materiais', slug: 'materials', active: false },
  ];

  private historicService = new HistoricService();

  private contextID: number | null = -1;
  // private URL_BUTTON_CREDIT = URL_BUTTON_CREDIT_BOOK;

  created() {
    // this.checkCredits();
    this.setSidebarVideo();
    this.getFile();
    this.takeGetBooks();
    this.loaderDataContext();
    this.setBreadCrumbs();
    this.onMobileChange();
    // this.getLastVideosWatched();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setSidebarExistence', false);
    clearInterval(this.progressButtonTimer);
    clearInterval(this.idSetIntervalMessageVideo);
    clearTimeout(this.timeLoadingVideo);
    clearTimeout(this.idTimeoutMessageVideo);
  }

  get bookTitleByContext() {
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
      return this.currentParentTopicTitle;
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
      return this.currentCourse?.title;
    }

    return this.currentBook?.title;
  }

  get isVimeo() {
    if (!(this.file?.detail?.vendor && this.file?.detail?.vendor === VIDEO_YOUTUBE)) {
      return COMPONENT_VIMEO;
    }
    return COMPONENT_YOUTUBE;
  }

  get getIdTopic() {
    return this.$route.params.topicID;
  }

  get getIdFile() {
    return this.$route.params.fileID;
  }

  get getPathVideo() {
    return this.$route.params.path;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get getContext() {
    return this.$route.params.context;
  }

  get filterVideoList() {
    return this.$store.getters.filterVideoList;
  }

  get newListGroupVideos() {
    return this.showOrder(this.listVideos);
  }

  // get plan() {
  //   return this.$store.getters.profile?.plan;
  // }

  // get isPlanFree() {
  //   return this.plan?.planID === LIST_PLAN_ID.FREE;
  // }

  // get hasLimitedCredit() {
  //   const quantityPermission = this.getQuantityPermission(LIST_PERMISSION.CREDITS_VIDEO) || 0;
  //   return quantityPermission ? quantityPermission !== QUANTITY_UNLIMITED_CREDIT : false;
  // }

  get permissionViewCurrentVideo() {
    // if (!this.isPlanFree) return true;

    // const { is_credits_video } = this.$store.getters.creditData;

    // return is_credits_video ? true : this.checkVideoHasBeenWatched();

    return true;
  }

  get filterTeachersVideo() {
    return this.$store.getters.filterTeachersVideo;
  }

  get allVideosAreHidden() {
    if (!this.filterTeachersVideo.length) return false;

    return this.filterTeachersVideo.every((teacherVideos: FilterTeachersVideo) => (!teacherVideos.checked));
  }

  async getBookTitle() {
    if (Object.keys(this.currentBook).length) return this.currentBook?.title;

    let book: Book | null = null;

    const booksStore = this.$store.getters.allBooks;

    if (booksStore.lenght) {
      book = booksStore.filter((data: Book) => data.slug === this.getPathVideo);
    } else {
      const booksService = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);
      const bookFound = booksService.find((data: Book) => data.slug === this.getPathVideo);
      book = bookFound;
    }

    return book;
  }

  setPermissionVideoForContextBook(videos: Array<FileVideo>) {
    return videos.map((video: FileVideo) => ({
      ...video,
      isPermission: true,
      // isPermission: this.checkPermissionViewVideoList(video.idVideo),
    }));
  }

  // checkPermissionViewVideoList(idVideoList: number) {
  //   if (!this.isPlanFree) return true;

  //   const { is_credits_video } = this.$store.getters.creditData;

  //   return is_credits_video ? true : this.checkVideoHasBeenWatched(idVideoList);
  // }

  async getFile() {
    try {
      this.removeMessageVideo(true);
      this.loadingVideo = true;

      this.file = await this.VideoService.getVideo(Number(this.getIdFile));
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o vídeo.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.loadingVideo = false;
    }
  }

  verifyMessageVideo() {
    if (this.typeMessage === 'error') this.removeMessageVideo();
  }

  removeMessageVideo(fromGetFile?: boolean) {
    this.activeMessageVideo = false;
    this.activeBtnProgress = false;
    this.disabledVideoMessage = fromGetFile ? this.disabledVideoMessage : true;
  }

  @Watch('getIdFile')
  async callFunctions() {
    await this.getFile();
    this.setSidebarVideo();
    this.setChecked();
    this.disabledVideoMessage = false;
  }

  // Faz o carregamento dos dados para cada contexto
  @Watch('getIdTopic')
  async loaderDataContext() {
    try {
      this.loadingVideosList = true;

      if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
        await this.loaderPlannerData();

      } else if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
        await this.loaderCourseData();

      } else {
        await this.getBook();
        await this.loaderBookData();
      }

      this.getIds();
      await this.getAllVideos();
      this.setChecked();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do tópico.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.loadingVideosList = false;
    }
  }

  async loaderPlannerData() {
    try {
      const { topic, contextID } = await this.setTopicPlanner();

      this.list = topic;
      this.contextID = contextID;

      if (this.list) this.setPermissionAndGroupVideos(this.list.videos);
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do planner.',
        status: 'error',
      });

      console.error(error);
    }
  }

  async loaderCourseData() {
    try {
      const { topic, contextID } = await this.setTopicCourse();

      this.list = {
        ...topic,
        title: this.currentParentTopicTitle,
      };

      this.contextID = contextID;

      this.setPermissionAndGroupVideos(this.list.videos);
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do curso.',
        status: 'error',
      });

      console.error(error);
    }
  }

  @Watch('getPathVideo')
  async loaderBookData() {
    try {
      this.isLoadingBooks = true;

      const list = await this.getTopicBook();
      const currentParentTopicTitle: ListBookTopics | undefined = await this.getCurrentTopicBook();

      this.list = {
        ...list,
        title: currentParentTopicTitle?.title || this.list?.title,
      };

      this.contextID = await this.getContextID();

      this.setPermissionAndGroupVideos(this.list.videos);
    } catch (error) {
      this.errorLoadingListTopic = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do caderno.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingBooks = false;
    }
  }

  @Watch('filterVideoList')
  setVideosFiltered() {
    this.setPermissionAndGroupVideos(this.filterVideoList);
  }

  setPermissionAndGroupVideos(videos: Array<FileVideo>) {
    const filterVideoList: any = this.setPermissionVideoForContextBook(videos);

    this.listVideos = this.regroupFiles(filterVideoList);
  }

  getIds() {
    if (this.list?.videos && this.list?.videos.length) {
      this.list.videos.forEach((video: Video) => {
        this.ids.push(video.idVideo);
      });
    }
  }

  setChecked() {
    this.allChecked.some((checkedItem: FileCheckedStatus) => {
      if (checkedItem?.idobject === Number(this.getIdFile)) {
        this.currentChecked = checkedItem.checked;

        return true;
      }

      this.currentChecked = false;

      return false;
    });
  }

  async getAllVideos() {
    if (this.ids.length > 99) {
      const response = await this.getHistoric('video', this.ids.splice(0, 100));

      this.allChecked.push(...response);
      await this.getAllVideos();
    } else {
      const response = await this.getHistoric('video', this.ids);

      this.allChecked.push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  async setCheckbox(type: string, checked: boolean) {
    this.currentChecked = !checked;

    await this.historicService.saveHistoric({
      idobject: this.file?.idVideo,
      type: 'video',
      checked: this.currentChecked,
    });

    const updateAllChecked = this.allChecked;

    const indexChecked = updateAllChecked.findIndex(
      (item) => item && item?.idobject === this.file?.idVideo,
    );

    if (indexChecked > -1) {
      updateAllChecked[indexChecked].checked = this.currentChecked;
    } else if (this.file) {
      updateAllChecked.push({
        idobject: this.file.idVideo,
        checked: this.currentChecked,
      });

    }

    this.allChecked = updateAllChecked;
  }

  @Watch('filterTeachersVideo')
  checkHasNextvideo() {
    const nextVideo = this.setNextVideo();

    if (!nextVideo || !Object.keys(nextVideo).length) {
      this.activeBtnProgress = false;
    }
  }

  setMessageVideo(option: string) {
    const sixtySecondTime = 60000;

    this.idSetIntervalMessageVideo = setInterval(() => {
      if (this.disabledVideoMessage) {
        clearInterval(this.idSetIntervalMessageVideo);
        return;
      }

      const nextVideo = this.setNextVideo();

      this.typeMessage = option;

      if (nextVideo && Object.keys(nextVideo).length) {
        this.lastVideoList = false;
        this.activeBtnProgress = true;

        this.progressBtnNextVideo();

        clearInterval(this.idSetIntervalMessageVideo);
      } else {
        this.lastVideoList = true;
        this.activeBtnProgress = false;
      }

      this.activeMessageVideo = true;
    }, 500);

    this.idTimeoutMessageVideo = setTimeout(() => {
      clearInterval(this.idSetIntervalMessageVideo);
    }, sixtySecondTime);
  }

  replayVideo(messageType: string, textButton: string) {
    if (messageType === 'error') {
      this.loadingVideo = true;

      this.timeLoadingVideo = setTimeout(() => {
        this.componentKey += 1;
        this.loadingVideo = false;
      }, 1000);
    }

    this.setTrackAmplitude(textButton);
    this.removeMessageVideo();
    this.btnClickReplayVideo = !this.btnClickReplayVideo;
  }

  setNextVideo(): FileVideo | Record<string, any> {
    const getVideos: FileVideo[][] | Record<string, any>[][] = this.getVideoFiles();

    const listConcatenatedVideos: FileVideo[] = this.concatenateVideos(getVideos);

    // USAR O QUE ESTÁ COMENTANDO QUANDO ATIVAR O SISTEMA DE CRÉDITOS

    // const videosAvailable: FileVideo[] = videosAvailable.filter((video: any) => !this.hasLimitedCredit && !video.hidden && video.isPermission);

    const videosAvailable: FileVideo[] = this.filterVideosAvailable(listConcatenatedVideos);

    const nextVideo: FileVideo | {} = videosAvailable.length ? this.getNextVideo(videosAvailable) : {};

    return nextVideo;
  }

  getVideoFiles(): FileVideo[][] | Record<string, any>[][] {
    return this.newListGroupVideos.map((item: ListFiles) => item.files);
  }

  concatenateVideos(videos: FileVideo[][] | Record<string, any>[][]) {
    let listConcatenatedVideos: FileVideo[] = [];

    videos.forEach((item: Array<Record<string, any>>) => {
      listConcatenatedVideos = concat(listConcatenatedVideos, item);
    });

    return listConcatenatedVideos;
  }

  filterVideosAvailable(videos: FileVideo[]) {
    return videos.filter((video: FileVideo) => !video.hidden && video.isPermission);
  }

  getNextVideo(videos: FileVideo[]) {
    let nextVideo: FileVideo | {} = {};

    const indexCurrentVideo = videos.findIndex((video: FileVideo) => (String(video.idVideo) === String(this.getIdFile)));

    if (indexCurrentVideo !== -1 && videos[indexCurrentVideo + 1]) {
      nextVideo = videos[indexCurrentVideo + 1];
    }

    return nextVideo;
  }

  getCurrentVideo(videos: Array<FileVideo>) {
    return videos.find((video: FileVideo) => String(video.idVideo) === String(this.getIdFile));
  }

  checkIfCurrentVideoOrderIsHigher(videos: Array<FileVideo>, currentVideo: FileVideo) {
    return videos.every((video: FileVideo) => currentVideo?.order > video.order);
  }

  @Watch('allVideosAreHidden')
  progressBtnNextVideo() {
    let widthSize = 0;
    this.widthBackgroundProgress = '0';

    this.progressButtonTimer = setInterval(() => {
      widthSize += 5;
      this.widthBackgroundProgress = `${widthSize}%`;

      if (!this.activeBtnProgress || this.allVideosAreHidden) {
        clearInterval(this.progressButtonTimer);
        widthSize = 0;
        this.activeBtnProgress = false;
        return;
      }

      if (widthSize === 100) {
        clearInterval(this.progressButtonTimer);
        widthSize = 0;
        this.nextVideoList();
      }
    }, 500);
  }

  async setFinishedVideo() {
    await this.setCheckbox('video', false);
    this.setMessageVideo('ended');
  }

  async nextVideoList() {
    this.setTrackAmplitude('Ir para o próximo');

    this.removeMessageVideo();

    const nextVideo = this.setNextVideo();

    if (nextVideo && Object.keys(nextVideo).length) {
      this.$router.push({
        name: 'videos',
        params: {
          context: this.getContext,
          path: this.getPathVideo,
          topicID: this.getIdTopic,
          fileID: String(nextVideo.idVideo),
          slugFile: nextVideo.slug,
        },
      });
    }
  }

  handleClickVideoFromList() {
    this.removeMessageVideo();

    clearInterval(this.progressButtonTimer);

    // this.viewedVideo = false;
  }

  @Provide('checked-video')
  async setCheckboxLocal(file: Record<string, any>, checked: boolean) {
    if (file.idVideo === this.file?.idVideo) {
      this.currentChecked = checked;
    }

    await this.historicService.saveHistoric({
      idobject: file.idVideo,
      type: 'video',
      checked,
    });

    const updateAllChecked = this.allChecked;

    const indexChecked = updateAllChecked.findIndex(
      (item) => item && item?.idobject === file.idVideo,
    );

    if (indexChecked > -1) {
      updateAllChecked[indexChecked].checked = checked;
    } else {
      updateAllChecked.push({
        idobject: file.idVideo,
        checked,
      });
    }

    this.allChecked = updateAllChecked;
  }

  async setBreadCrumbs() {
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER) {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE) {
      this.setBreadCrumbsCourse();
      return;
    }

    const bookTitle = await this.getBookTitle();

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: bookTitle?.title, to: `/caderno/${this.getPathVideo}` },
      { title: 'aulas', to: null },
    ]);
  }

  setBreadCrumbsPlanner() {
    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'aulas', to: null },
    ]);
  }

  setBreadCrumbsCourse() {
    const splitPath = this.getPathVideo.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      { title: 'aulas', to: null },
    ]);
  }

  @Watch('$responsive.isMobile')
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
    this.$store.commit('setSidebar', !this.isMobile);
  }

  setSidebarTopic() {
    if (this.getContext === LIST_NAME_CONTEXT.PLANNER && this.currentWeekPlanner && this.currentWeekPlanner.length) {
      return this.currentWeekPlanner;
    }

    if (this.getContext === LIST_NAME_CONTEXT.COURSE && this.currentCourseWeek?.topics && this.currentCourseWeek?.topics.length) {
      return this.currentCourseWeek.topics;
    }

    if (this.currentBook && Object.entries(this.currentBook).length) {
      return this.currentBook?.topics;
    }

    return {};
  }

  @Watch('currentWeekPlanner')
  @Watch('currentCourseWeek')
  @Watch('currentBook')
  setSidebarVideo() {
    const topics = this.setSidebarTopic();

    const ComponentClass = Vue.extend(VideoSidebar);
    const instance = new ComponentClass({
      propsData: {
        data: topics,
        context: this.getContext,
        path: this.getPathVideo,
      },
    });

    this.setSidebar(instance);
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Video',
          local,
        },
      },
    });
  }

  // checkCredits() {
  //   if (!this.isPlanFree) return;

  //   this.getCredits(ContextOptionsCredits.videos);
  // }

  // async saveViewVideoCredits(percentVideo: number) {
  //   try {
  //     if (!this.isPlanFree || this.viewedVideo || this.checkVideoHasBeenWatched() || !this.file) return;

  //     const {
  //       idVideo,
  //       slug,
  //       title,
  //       time,
  //       detail,
  //     } = this.file;

  //     const videoDate = {
  //       idobject: idVideo,
  //       type: 'video',
  //       percent: percentVideo,
  //       checked: this.currentChecked,
  //       object: {
  //         slug,
  //         idTopic: String(this.getIdTopic),
  //         title,
  //         titleTopic: this.list.title,
  //         titleBook: this.bookTitleByContext,
  //         time,
  //         book: this.getPathVideo,
  //         context: this.getContext,
  //         path: this.getPathVideo,
  //         thumb: detail?.thumb?.length && detail?.thumb[3] && detail?.thumb[3].link,
  //       },
  //     };

  //     await this.historicService.saveHistoric(videoDate);

  //     this.BookService.saveViewVideoCredits(idVideo);

  //     this.$store.commit('setRecentlyViewedVideo', idVideo);

  //     this.viewedVideo = true;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // checkVideoHasBeenWatched(idVideoList?: number): boolean {
  //   const { idsVideoWatched, recentlyViewedVideo } = this.$store.getters;
  //   const { idVideo } = this.file;

  //   const videoIsInEndpointList = idsVideoWatched.length
  //     ? idsVideoWatched.includes(idVideoList || idVideo) : false;

  //   const videoIsRecentlyViewedVideo = recentlyViewedVideo.length
  //     ? recentlyViewedVideo.includes(idVideoList || idVideo) : false;

  //   return videoIsInEndpointList || videoIsRecentlyViewedVideo;
  // }

  // async getLastVideosWatched() {
  //   try {
  //     if (!this.isPlanFree) return;

  //     this.$store.commit('setIsLoadingVideosWatched', true);

  //     const videoList = await this.historicService.getMyListVideosWatched({
  //       bookSlug: this.getPathVideo,
  //       isFree: this.isPlanFree,
  //     });

  //     if (videoList.length) {
  //       const idsVideosWatched = this.getVideoIds(videoList);

  //       this.$store.commit('setIdsVideoWatched', idsVideosWatched);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     this.$store.commit('setIsLoadingVideosWatched', false);
  //   }
  // }

  // getVideoIds(videos: Array<VideosHistoric>) {
  //   if (!videos.length) return [];

  //   return videos.map((video: VideosHistoric) => video.idobject);
  // }
}
