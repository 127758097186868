import { Vue, Component } from 'vue-property-decorator';

import router from '@/router';

import BookService from '@/services/Book/BookService';

import { ListBookTopics, Book as IBook } from '@/globalInterfaces/Book';

const CONTEXT_PLANNER = 'planner';

@Component
export default class BookData extends Vue {
  protected allBooks: Array<IBook> = [];
  protected currentBook: Record<string, any> = {};

  protected BookService = new BookService();

  async getTopicBook() {
    const { topicID } = router.currentRoute.params;
    const { path } = router.currentRoute.params;

    const bookInfo = await this.getBookIdByName(path);
    const topic = await this.BookService.getBookTopic(bookInfo?.idBooks, Number(topicID));

    if (topic) return topic;

    throw new Error('Não foi possível retornar o tópico do caderno.');
  }

  async getCurrentTopicBook() {
    let currentTopic: ListBookTopics | undefined;

    try {
      const { path, topicID } = router.currentRoute.params;

      const bookInfo = await this.getBookIdByName(path);
      const listTopics = await this.BookService.getTopicsBook(bookInfo?.idBooks, path);

      currentTopic = this.searchTopicBook(listTopics, Number(topicID));
    } catch (error) {
      console.error(error);
    }

    return currentTopic;
  }

  searchTopicBook(topicList: Array<ListBookTopics>, idTopic: number) {
    const currentTopic = topicList.find((topic: ListBookTopics) => topic.idTopic === idTopic);

    return currentTopic;
  }

  async getBookIdByName(bookName: string) {
    let allBooks = this.allBooks || [];
    let bookCurrent: IBook | any = null;

    if (!allBooks.length) {
      allBooks = await this.getBooks();
    }

    bookCurrent = allBooks.find((book: IBook) => book?.slug === bookName);

    return bookCurrent;
  }

  async getBooks() {
    let getAllbooks = [];

    try {
      getAllbooks = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);
      this.allBooks = getAllbooks;

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cadernos.',
        status: 'error',
      });

      console.error(error);
    }

    return getAllbooks;
  }

  async getContextID() {
    const { path } = router.currentRoute.params;
    const currentBook = await this.getBookIdByName(path);

    if (currentBook) return currentBook?.idBooks;
    return null;
  }

  async takeGetBooks() {
    if (router.currentRoute.params.context === CONTEXT_PLANNER) return;

    await this.getBooks();
  }

  async getBook() {
    const { path } = router.currentRoute.params;

    this.currentBook = await this.BookService.book(path);
  }
}
