import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SetSidebar extends Vue {

  setSidebar(instance: any) {
    this.$store.commit('setSidebarExistence', true);

    if (this.$responsive.isMobile) {
      this.$nextTick(() => {
        this.$store.commit('setSidebar', true);
      });

      this.closedSidebar();
    } else {
      this.$store.commit('setSidebar', true);
    }

    this.$sidebar.setSidebar(instance);
  }

  private closedSidebar() {
    setTimeout(() => {
      this.$store.commit('setSidebar', false);
    }, 2500);
  }
}
