import { Vue, Component } from 'vue-property-decorator';

import MaterialCategoryService from '@/services/MaterialCategory/MaterialCategoryService';

const ID_NAME_MATERIAL_TYPE_CONTEUDO = 1;

interface Category {
  ID: number;
  category: string;
  priority: number;
}

interface ListFiles {
  files: Array<Record<string, any>>;
  group: number;
}

@Component
export default class ListMixin extends Vue {
  private materialCategoryList: Array<Category> = [];

  private MaterialCategoryService = new MaterialCategoryService();

  created() {
    this.getMaterialCategories();
  }

  listGroupFiles(groups: number[], files: []) {
    const newList: ListFiles[] = [];

    groups.forEach((group: number) => {
      const array = files.filter((file: Record<string, any>) => file.IDMaterialCategory === group);

      newList.push({
        group,
        files: array,
      });
    });

    return newList;
  }

  setNameGroup(group: number) {
    const materialCategory = this.findMaterialCategory(group);

    return materialCategory && materialCategory?.category;
  }

  groupFiles(files: []) {
    const groupsFile = [...new Set(files.map((file: Record<string, any>) => file.IDMaterialCategory))];

    return groupsFile;
  }

  regroupFiles(files: []) {
    const groups: number[] = this.groupFiles(files);

    const listFiles: ListFiles[] = this.listGroupFiles(groups, files);

    return this.showOrder(listFiles);
  }

  regroupFilesFree(files: []) {
    const listFiles: Array<Record<string, any>> = [
      {
        group: ID_NAME_MATERIAL_TYPE_CONTEUDO,
        files,
      },
    ];

    return listFiles;
  }

  showOrder(listFiles: ListFiles[]) {
    let orderList: ListFiles[] = listFiles;

    orderList = this.orderPriorityGroup(orderList.map((groupFile: ListFiles) => ({
      files: this.orderPriorityFiles(this.orderFilesOrder(this.orderFilesTitles(groupFile.files))),
      group: groupFile.group,
    })));

    return orderList;
  }

  orderPriorityGroup(files: ListFiles[]) {
    const sortFiles = files.sort((a: ListFiles, b: ListFiles) => {
      const orderItemA = this.findMaterialCategory(a.group)?.priority || 0;
      const orderItemB = this.findMaterialCategory(b.group)?.priority || 0;

      if (orderItemA < orderItemB) return -1;
      if (orderItemA > orderItemB) return 1;

      return 0;
    });

    return sortFiles;
  }

  orderPriorityFiles(files: Array<Record<string, any>>) {
    const sortFiles = files.sort((a: Record<string, any>, b: Record<string, any>) => {
      const orderItemA = this.findMaterialCategory(a.IDMaterialCategory)?.priority || 0;
      const orderItemB = this.findMaterialCategory(b.IDMaterialCategory)?.priority || 0;

      if (orderItemA < orderItemB) return -1;
      if (orderItemA > orderItemB) return 1;
      return 0;
    });

    return sortFiles;
  }

  orderFilesTitles(files: Array<Record<string, any>>) {
    const sortFiles = files.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;

      return 0;
    });

    return sortFiles;
  }

  orderFilesOrder(files: Array<Record<string, any>>) {
    const sortFiles = files.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;

      return 0;
    });

    return sortFiles;
  }

  findMaterialCategory(group: number) {
    const materialCategory: Category | undefined = this.materialCategoryList.find((category: Category) => group === category.ID);

    return materialCategory;
  }

  async getMaterialCategories() {
    this.materialCategoryList = await this.MaterialCategoryService.getMaterialCategory();
  }
}
